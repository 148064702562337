<template>
  <div class="search__results">
    <div class="wrapper__lg">
      <h3>
        Search results for '{{
          data.query !== '' ? data.query : urlParamsCheck
        }}'
      </h3>
      <instant-search
        :terms="urlParamsCheck"
        :customer="props.customer"
        :search_active="search_active"
        :source="props.source"
        @searchData="handleSearchData"
        @searchQuery="updateSearchQuery"
      />
    </div>
    <loading v-if="data.loading" size="small" />
    <template v-else>
      <template v-if="search_active && data.allEmpty === false">
        <div class="search__results__navigation wrapper__lg">
          <button
          v-if="data.productsFound > 0"
            @click="toggleResults('products')"
            :class="[
              'btn__base',
              'btn__base--outline',
              { 'btn--active': data.activeResults === 'products' },
            ]"
          >
            Products ({{ data.productsFound }})
          </button>
          <button
            v-if="data.collections.length"
            @click="toggleResults('collections')"
            :class="[
              'btn__base',
              'btn__base--outline',
              {
                'btn--active': data.activeResults === 'collections',
              },
            ]"
          >
            Collections ({{ data.collections.length }})
          </button>
          <button
            v-if="data.pagesFound > 0"
            @click="toggleResults('pages')"
            :class="[
              'btn__base',
              'btn__base--outline',
              { 'btn--active': data.activeResults === 'pages' },
            ]"
          >
            Pages ({{ data.pagesFound }})
          </button>
        </div>
        <div class="search__results__tabs">
          <!-- Render product results -->
          <div
            v-if="data.activeResults === 'products' && data.productsFound >= 1"
          >
            <collection
              :customer="customer"
              :query="data.query !== '' ? data.query : urlParamsCheck"
              :products_count="String(data.productsFound)"
              page_template="search"
            ></collection>
          </div>
          <!-- Render collection results -->
          <div
            v-if="data.activeResults === 'collections'"
            class="search__results__collections wrapper__lg"
          >
            <ul>
              <li v-for="collection in data.collections" :key="collection.id">
                <a :href="'/collections/' + collection.handle"
                  ><p class="accent underline">{{ collection.title }}</p></a
                >
              </li>
            </ul>
          </div>
          <!-- Render page results -->
          <div
            v-if="data.activeResults === 'pages'"
            class="search__results__pages wrapper__lg"
          >
            <ul>
              <li v-for="page in data.pages" :key="page.id">
                <a :href="'/pages/' + page.handle"
                  ><p class="accent underline">{{ page.title }}</p></a
                >
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-if="data.allEmpty === true">
        <div class="search__results__no__results wrapper__lg">
          <p class="accent">Sorry, no search results.</p>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue';
import InstantSearch from './InstantSearch.vue';
import Collection from './Collection.vue';
import Loading from './Loading.vue';

const props = defineProps({
  search_active: {
    type: Boolean,
    required: true,
    default: () => false,
  },
  customer: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  source: {
    type: String,
    required: false,
  },
});

const urlParamsCheck = computed(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString).get('q');
  return urlParams;
});

const data = reactive({
  query: '',
  suggestions: [],
  pages: [],
  products: [],
  collections: [],
  productsFound: 0,
  pagesFound: 0,
  allEmpty: true,
  activeResults: 'products',
});

const handleSearchData = (value) => {
  data.loading = true;
  data.suggestions = value.suggestions;
  data.pages = value.pages;
  data.products = value.products;
  data.collections = value.collections;
  data.productsFound = value.total_product ? value.total_product : 0;
  data.pagesFound = value.pages.length ? value.pages.length : 0;
  data.allEmpty = value.all_empty;
  setTimeout(() => {
    data.loading = false;
  }, 1000);
};

const updateSearchQuery = (value) => {
  data.query = value;
};

const toggleResults = (selectedTab) => {
  data.activeResults = selectedTab;
};
</script>
